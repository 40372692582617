angular.module('serverSideErrors', [])
	.directive("serverSideErrors", function () {

		return {
			restrict: 'E',
			transclude: true,
			replace: true,
			scope: {errors: '='},
			template: '<ul class="server-side-errors" ng-show="errors && errors.length"><li ng-repeat="e in errors">{{ e }}</li></ul>',
			link: linkFn
		};

		function linkFn(scope, element, iAttr, ngModel) {
			scope.$watch('errors', function (value) {
				if (angular.isDefined(value)) {
					//angular.element('.content-scroll').animate({scrollTop: angular.element("#errors").offset().top}, "slow");
					//
					//angular.element(element).removeClass('ng-hide-remove').addClass('ng-hide-remove');
					//setTimeout(function () {
					//	angular.element(element).removeClass('ng-hide-remove');
					//}, 1000);
				}
			});
		}

	})
;
