//angular.module('inputFocus',[]).directive('inputFocus',
//	function($timeout) {
//		return {
//			restrict: 'AC',
//			link: function(_scope, _element) {
//				$timeout(function(){
//					_element[0].focus();
//				}, 0);
//			}
//		};
//	});



angular.module('focusOn', []).directive('focusOn', function() {
	return function(scope, elem, attr) {
		scope.$on('focusOn', function(e, name) {
			if(name === attr.focusOn) {
				elem[0].focus();
			}
		});
	};
});

angular.module('focusOn').factory('focus', function ($rootScope, $timeout) {
	return function(name) {
		$timeout(function (){
			$rootScope.$broadcast('focusOn', name);
		});
	}
});